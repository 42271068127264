import React from "react"
import Layout from "../components/Layout"
import marketCapChart from "../images/marketcapchart.png"
import "./RiskReview.scss"

const intro = {
    title: "Complimentary Portfolio Risk Review",
    p1Start:
        "The Stock Market has run far ahead of the economic recovery and now represents a High Risk Scenario.  Yet many investors remain exposed to more risk than they realize. ",
    p1Bold:
        "Savvy believes there has never been a better time to reassess your risk and is happy to offer a complimentary review. ",
    p1End: "It never hurts to get a second opinion.",
    p2:
        "With both bonds and stocks at record extremes and inflation rising on the horizon, this is one of the most difficult investment climates in history.  But there are a variety of strategies that can help reduce your risk and scale it appropriately to your personal scenario.  For example, broad diversification, long/short strategies, and hedged income strategies are all useful means to mitigate risks while maintaining potential.  Moreover, preparedness goes beyond merely managing volatility, and better yet, looks to turn volatility into long term opportunities.",
}

const bullets = [
    "Are you exposed to more risk that you realize?",
    "Are you appropriately diversified?",
    "Are you prepared for a potentially sharp correction, or even another financial crisis?",
    "Do you know what your downside risk is?",
    "Are you prepared for rising inflation?",
    "Is your portfolio well aligned with your financial conditions and your personal risk tolerance?",
    "Do you have other questions about your portfolio and investments?",
]

const marketValuation = {
    title: "Market valuation",
    p1:
        "One of the most comprehensive measures of market valuation is the ratio of Market Capitalization to GDP.  (Market Capitalization is essentially the total value of the entire stock market.) (GDP, Gross Domestic Product, is the entire economic output of the nation.)",
    p2:
        "Historically, Market Capitalization is on average about 100% of GDP, that is, about equal.  But in the chart below it's inescapably clear that Market Capitalization has expanded far beyond the economy.  The current ratio (as of March 15, 2021) is at a record high of 190%.  This eclipses by far the prior high of 143% set during the dotcom bubble of 2000.  And the implication of this stratospheric valuation is that market prices would have to fall nearly 50% just to get back to the long term average.  But even worse, once a major decline sets in, fear takes hold and typically drives prices well below average, as can be seen in the chart following the 2000 and 2008 bear markets.",
    p3:
        'Moreover, complacency and overconfidence are also at very high levels and serve as further warning signs.  Gamestonk, SPACs, NFTs, and Bitcoin represent the most conspicuous manias of the day.  And these are just the kinds of "irrational exuberance" that have historically led to market tops and major declines.',
    p4:
        "Consequently, while it is certainly possible for the market to continue grinding higher for some time, Savvy believes that given current market extremes, there is much greater risk to the downside than potential for further gains on the upside. ",
    p5:
        'Yet complacency is the natural consequence of a long soaring market.  Further, many in the financial media facilitate such complacency by encouraging investors to continue buying even at these extremes, arguing that everything\'s golden, and even if there is a decline you can always ride it out.  (Note that it took 13 years to "ride it out" after the 2000 tech bubble.)  Savvy believes this is suspect advice, especially without taking individual considerations into account.',
    p6:
        "And while Wall Street is indeed populated with many a market cheerleader, there are also many seasoned market analysts who are similarly sounding the warning bells.",
    quote1:
        '"The long, long bull market since 2009 has finally matured into a fully-fledged epic bubble."',
    quoteAuthor1:
        "–Jeremy Grantham, Co-Founder and Chief Investment Strategist of GMO: Grantham, Mayo, & van Otterloo",
    quote2:
        "\"I've been doing this since 1978, and this is the wildest market I've ever seen....  I have no clue what the market is going to do in the near term...  But I would say the next three-to-five years are going to be very, very challenging.\"",
    quoteAuthor2: "–Stanley Druckenmiller, Founder of Duquesne Capital",
    p7:
        "Similarly, whether they expect a major correction or merely a long period of stagnation, many analysts are forecasting weak returns for the next decade.  JP Morgan, Blackrock, Vanguard, Research Affiliates, Morningstar and GMO are forecasting average annual returns ranging from just 5% to -5%.",
    p8:
        "No one can say for certain when a major correction might set in.  But we do know the current level of downside risk is abnormally high, while the potential for significant further gains appears limited. ",
    p9:
        "One way or another, Savvy encourages every investor to take this opportunity to reassess your portfolio positioning to ensure that you're prepared for potentially significant volatility ahead.",
}

export default function Riskreview() {
    return (
        <Layout title="Complimentary Portfolio Risk Review">
            <div className="risk-review">
                <div className="risk-review__page-content">
                    <div className="risk-review__page-section">
                        <div className="risk-review__page-title">
                            {intro.title}
                        </div>
                        <div className="risk-review__section-text">
                            {intro.p1Start}
                            <span className="risk-review__bolded">
                                {intro.p1Bold}
                            </span>
                            {intro.p1End}
                        </div>
                        <ul>
                            {bullets.map((bullet, index) => (
                                <li className="risk-review__bullet" key={index}>
                                    {bullet}
                                </li>
                            ))}
                        </ul>
                        <div className="risk-review__section-text">
                            {intro.p2}
                        </div>
                    </div>
                    <div className="risk-review__page-section">
                        <div className="risk-review__section-title">
                            {marketValuation.title}
                        </div>
                        <div className="risk-review__section-text">
                            {marketValuation.p1}
                        </div>
                        <div className="risk-review__section-text">
                            {marketValuation.p2}
                        </div>
                        <img
                            className="risk-review__image"
                            src={marketCapChart}
                            alt="a diagram of the GDP trend, compared to the market capitalization trend"
                        />
                        <div className="risk-review__section-text">
                            {marketValuation.p3}
                        </div>
                        <div className="risk-review__section-text">
                            {marketValuation.p4}
                        </div>
                        <div className="risk-review__section-text">
                            {marketValuation.p5}
                        </div>
                        <div className="risk-review__section-text">
                            {marketValuation.p6}
                        </div>
                        <div className="risk-review__page-section">
                            <div className="risk-review__section-quote">
                                <div className="risk-review__section-quote--quote">
                                    {marketValuation.quote1}
                                </div>
                                <div className="risk-review__section-quote--author">
                                    {marketValuation.quoteAuthor1}
                                </div>
                            </div>
                            <div className="risk-review__section-quote">
                                <div className="risk-review__section-quote--quote">
                                    {marketValuation.quote2}
                                </div>
                                <div className="risk-review__section-quote--author">
                                    {marketValuation.quoteAuthor2}
                                </div>
                            </div>
                        </div>
                        <div className="risk-review__section-text">
                            {marketValuation.p7}
                        </div>
                        <div className="risk-review__section-text">
                            {marketValuation.p8}
                        </div>
                        <div className="risk-review__section-text">
                            {marketValuation.p9}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
